import {
  ACCESSORIES_SUB_CATEGORY,
  BOYS_CATEGORY,
  GIRLS_CATEGORY,
  categories,
  sizes,
  subCategories,
  types,
} from '../../config/configListing';
import { normalizeTypeKey } from '../../util/configHelpers';

export const getSizeOptions = searchParams => {
  const categoryParams = searchParams?.pub_category;
  const subCategoryParams = searchParams?.pub_subCategory;
  const typeParams = searchParams?.pub_type;

  const typeKey = normalizeTypeKey(categoryParams, subCategoryParams, typeParams);
  if (!typeKey) return null;
  const isCustomSelect =
    (categoryParams === BOYS_CATEGORY || categoryParams === GIRLS_CATEGORY) &&
    subCategoryParams !== ACCESSORIES_SUB_CATEGORY;

  const sizeGroups = sizes.enumSections[categoryParams][subCategoryParams][typeKey] ?? {};
  const entries = Object.entries(sizeGroups);

  return {
    options: entries.length > 1 ? sizeGroups : entries.map(([, value]) => value).flat(),
    isCustomSelect,
  };
};

export const getCustomSizesFilters = searchParams => {
  const { options, isCustomSelect } = getSizeOptions(searchParams);
  if (options?.length === 1) return null;
  return {
    key: 'size',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: options,
    filterConfig: {
      indexForSearch: true,
      filterType: isCustomSelect ? 'SelectMultipleFilterCustom' : 'SelectMultipleFilter',
      label: 'Size',
      group: 'secondary',
    },
    showConfig: {
      label: 'Size',
      isDetail: true,
    },
    saveConfig: {},
  };
};

export const getCustomTypesFilters = searchParams => {
  const categoryParams = searchParams?.pub_category;
  const subCategoryParams = searchParams?.pub_subCategory;
  return {
    key: 'type',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: types.enumSections[categoryParams][subCategoryParams],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Type',
      isDetail: true,
    },
    saveConfig: {},
  };
};

export const getCustomCategoriesFilters = () => {
  return {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: categories.enumOptions,
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {},
  };
};

export const getCategoriesFilterScheme = () => {
  return {
    key: 'category',
    scope: 'public',
    filterConfig: {
      indexForSearch: true,
    },
  };
};

export const getSubCategoriesFilterScheme = () => {
  return {
    key: 'subCategory',
    scope: 'public',
    filterConfig: {
      indexForSearch: true,
    },
  };
};

export const getSizeFilterScheme = () => {
  return {
    key: 'size',
    scope: 'public',
    filterConfig: {
      indexForSearch: true,
    },
  };
};

export const getTypeFilterScheme = () => {
  return {
    key: 'type',
    scope: 'public',
    filterConfig: {
      indexForSearch: true,
    },
  };
};

export const getCustomSubCategoriesFilters = searchParams => {
  const categoryParams = searchParams?.pub_category;
  const combinedSubcategories = categoryParams
    ?.split(',')
    .filter(c => c !== 'brands')
    .reduce((allSubCategories, c) => {
      return allSubCategories.concat(subCategories.enumSections[c]);
    }, []);
  return {
    key: 'subCategory',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: combinedSubcategories,
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Sub Categories',
      group: 'primary',
    },
    showConfig: {
      label: 'Sub Category',
      isDetail: true,
    },
    saveConfig: {},
  };
};
